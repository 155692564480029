import React from 'react';
import './style.scss';
import ControlPanel from "../../components/ControlPanel/ControlPanel";
import SettingsPanel from "../../components/SettingsPanel/SettingsPanel";

function SettingsPage({ chatToken, hotelId, disabledPages }) {
  return (
    <div className="settings-body">
      <ControlPanel page="settings" disabledPages={disabledPages}></ControlPanel>
      <SettingsPanel chatToken={chatToken} hotelId={hotelId}></SettingsPanel>
    </div>
  )
}

export default SettingsPage;
