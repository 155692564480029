import React from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import InboxPanel from '../../components/InboxPanel/InboxPanel';

function InboxPage({ chatToken, hotelId, socket, disabledPages }) {

  return (
    <div className="dashboard-body">
      <ControlPanel page="inbox" disabledPages={disabledPages}></ControlPanel>
      <InboxPanel chatToken={chatToken} hotelId={hotelId} socket={socket}></InboxPanel>
    </div>
  );
}

export default InboxPage;
