import React, { useState, useEffect } from 'react';
import './style.scss';
import axios from 'axios';
import Table from '../Table/Table';
import exportIcon from '../../assets/export-icon.svg';


const LeadsPanel = ({hotelId}) => {
    const [formData, setFormData] = useState([]);
    const [formObjects, setformObjects] = useState({});
    const [group, setGroup] = useState('');

    const getLeadForm = async (leadForm) => {
        try {
            const response = await axios.get(`/api/typeform/getTypeForms/${hotelId}/${leadForm}`);
            const allFormsData = response.data.map(form => {
                return {
                    id: form.id,
                    issued: form.issued,
                    key: form.key,
                    timestamp: form.timestamp,
                    ...form
                };
            });
            setFormData(allFormsData)
            
        } catch (err) {
            console.error("Error fetching typeforms: ", err);
        }
    };

    const getFormType = async () => {
        try{
            const response = await axios.get(`/api/typeform/getFormType/${hotelId}/LeadForms`);
            const allFormTypes = Object.entries(response.data);
            setformObjects(response.data);
            if(allFormTypes.length > 0){
                setGroup(allFormTypes[0][0]);
            }
        }catch(err){
            console.error("Error in getFormType React: ", err);
        }
    }

    const isUrl = (str) => {
        try {
          new URL(str);
          return true;
        } catch (_) {
          return false;
        }
      };

    const wrapInQuotes = (str) => `"${str.replace(/"/g, '""')}"`;

    const getUniqueQuestions = (filteredFormData) => {
        const questionsSet = new Set();
        filteredFormData.forEach(entry => {
            entry.questions.forEach(q => {
            if (!isUrl(q.response)) {
                questionsSet.add(q.question);
            }
            });
        });
        
        return Array.from(questionsSet);
    };
      
    const convertFormDataToCSV = (filteredFormData) => {
        const headers = getUniqueQuestions(filteredFormData).map(wrapInQuotes);
        const csvRows = [headers.join(',')];
        filteredFormData.forEach(entry => {
            const responses = headers.map(question => {
            const matchingQuestion = entry.questions.find(q => q.question === question.replace(/"/g, ''));
            return matchingQuestion && !isUrl(matchingQuestion.response) ? matchingQuestion.response : '';
            });
            csvRows.push(responses.join(','));
        });
        
        return csvRows.join('\n');
    };

    const downloadFormDataAsCSV = (filteredFormData) => {
        if (!filteredFormData || filteredFormData.length === 0) {
            alert('No data available to export');
            return;
        }
        const csvData = convertFormDataToCSV(filteredFormData);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'form_data_responses.csv');
        a.click();
        window.URL.revokeObjectURL(url);
    };
    
    useEffect(() => {
        getFormType();
    }, []);

    useEffect(() => {
        if(group){
            getLeadForm(group);
        }
    }, [group]);

    const filteredFormData = formData.filter(form => form.key === group);

    const renderFormTable = () => {
        switch(group){
            case 'AgreementForms':
                return(
                    <>
                        {formData.length > 0 ? (
                            <>            
                                <div className='leads-label-row'>
                                    <div id='over-checkbox'></div>
                                    <div className='leads-label' id='over-name'>Name</div>
                                    <div id='over-content'></div>
                                    <div className='leads-label' id='over-date'>Date</div>
                                    <div className='leads-label' id='over-status'>Status</div>
                                </div>
                                <Table group='agreements' data={filteredFormData} hotelId={hotelId}/>
                            </>
                        ) : (
                            <>
                                <h1 id='no-leads' style={{}}>No agreements at the moment</h1>
                            </>
                        )}
                    </>
                )
            default:
                return(
                    <>
                        {formData.length > 0 ? (
                            <>
                                <div className='leads-label-row'>
                                    <div id='over-checkbox'></div>
                                    <div className='leads-label' id='over-name'>Name</div>
                                    <div id='over-content'></div>
                                    <div className='leads-label' id='over-date'>Date</div>
                                    <div className='leads-label' id='over-status'>Status</div>
                                </div>
                                <Table group='leads' data={filteredFormData} hotelId={hotelId}/>
                            </>
                        ) : (
                            <>
                                <h1 id='no-leads'>No leads at the moment</h1>
                            </>
                        )}
                    </>
                )
        }   
    }
    return (
        <div className="leads-panel-body">
            
            <div className="leads-panel-content">
                <div className='leads-panel-header'>
                    <div className="leads-panel-title">Leads</div>
                    {group === 'AgreementForms' && (
                        <>
                            <div className="export-button-container">
                                <img className='export-icon' src={exportIcon} onClick={() => downloadFormDataAsCSV(filteredFormData)}></img>
                            </div>
                        </>
                    )}
                </div>
                <div className='leads-button-row'>
                    <div className='form-tab-ctn' >
                        {Object.entries(formObjects).map(([key, value], index) => (
                            <div key={index}>
                                <div className={`form-tab ${group === key ? 'selected' : ''}`} onClick={() => setGroup(key)}>{value}</div>
                            </div>
                        ))}
                    </div>
                    {renderFormTable()}
                </div>
            </div>
        </div>
    );
}

export default LeadsPanel;