import React from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import ArrivalsPanel from '../../components/ArrivalsPanel/ArrivalsPanel';

function ArrivalsPage({ chatToken, hotelId, disabledPages }) {
    return (
      <div className="arrivals-body">
        <ControlPanel page="arrivals" disabledPages={disabledPages}></ControlPanel>
        <ArrivalsPanel ></ArrivalsPanel>
      </div>
    )
  }

export default ArrivalsPage;