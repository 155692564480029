import React, { useState, useEffect, useRef } from "react";
import paperClip from "../../../assets/paper-clip.png";
import trashCan from "../../../assets/trash-can-icon.png";
import "./style.scss";
import { animateScroll } from "react-scroll";
import axios from "axios";
import toast from 'react-hot-toast';
import loadingSpinner from '../../../assets/eclipse-1s-200px.gif'
const Conversation = ({ conversation, guestPhoneNumber, hotelId }) => {
	const [mediaUrls, setMediaUrls] = useState({});
	const [attachedFiles, setAttachedFiles] = useState([]);
	const [messages, setMessages] = useState(conversation);
	const [newMessage, setNewMessage] = useState("");
	const [mediaTempUrls, setMediaTempUrls] = useState([]);
	const [disableMessageButton, setDisableMessageButton] = useState(false);
	const [pendingMessage, setPendingMessage] = useState(null);
	const [loading, setLoading] = useState(true);
	const [listSize, setListSize] = useState(12);
	const containerRef = useRef(null);
	let mediaRef = useRef([]);

	const scrollToBottom = () => {
		const element = document.getElementById("conversation-messages-row");
		element.scrollTop = element.scrollHeight;
	};

	const scrollToBottomSmooth = () => {
		animateScroll.scrollToBottom({
			containerId: "conversation-messages-row",
			duration: 250,
		})
	};

	const formatDateToCustomString = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
	}

	const isMessageFromUser = (message) => {
		return message.author === 'hotel';
	};

	const getMostRecentTimestamp = () => {
		if (messages.length > 0) {
			const options = {
				hour: "numeric",
				minute: "numeric",
				hour12: true,
				month: "short",
				day: "numeric",
				year: "numeric",
			};
			const latestTimestamp =
				messages[messages.length - 1].timestamp;
			return latestTimestamp.toLocaleString("en-US", options);
		} else {
			return "Loading...";
		}
	};

	const handleIndividualTimestamp = (timestamp) => {
		const options = {
			month: "short",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "2-digit",
			hour12: true,
		};
		let formattedTimestamp = new Date(timestamp).toLocaleString("en-US", options);
		// removes the comma after the day
		formattedTimestamp = formattedTimestamp.replace(",", "");
		// Adds the period after the abbreviated month except "May".
		formattedTimestamp = formattedTimestamp.replace(
			/\b(?:(Jan|Feb|Mar|Apr|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b)/,
			"$1."
		);
		return formattedTimestamp;
	};

	const alertToast = (message, color, icon, duration = 5000) => {
		const id = toast((t) => (
		<div>
			<span onClick={() => toast.dismiss(t.id)}>
			{message}
			</span>
		</div>
		), {
		position: 'top-right',
		icon: icon,
		duration: duration,
		style: {
			background: color,
			color: '#ffffff',
		}
		});
		return id;
	}

	const sendMessage = async () => {
		try{
			setDisableMessageButton(true);
			let message = newMessage;
			const data = attachedFiles;
			const hotelPhoneNumber = await axios.get(`/api/hotel/${hotelId}/phone-number`);
			if (message || data) {
				try{
					const currentTime = Date.now();
					const messageContent = {
						body: message,
						dateCreated: currentTime,
						media: data,
						status: 'sending',
						author: 'hotel',
						authorId: hotelId,
						type: data.length > 0 ? 'media' : 'text'
					};
					setPendingMessage(messageContent);
					const formData = new FormData();
					for (const image of data) {
						formData.append("media", image);
					}
					formData.append('hotelId', hotelId);
					formData.append('guestPhoneNumber', guestPhoneNumber);
					formData.append('hotelPhoneNumber', hotelPhoneNumber.data.hotelPhoneNumber);
					if(!message){
						message = " ";
					}
					formData.append('messageContent', message);
					alertToast("Sending message...", '#01a0c6', '🚀');
					const sendMessage = await axios.post("/api/chat/send-message", formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
					const status = sendMessage?.data?.message?.status;
					if(status === 'delivered' || status === 'read'){
						setDisableMessageButton(false);
						setNewMessage("");
						setAttachedFiles([]);
						alertToast("Message sent successful", '#01a0c6', '✓');
						setPendingMessage(null);
					}else{
						alertToast('Failed to send message', '#cf4343', '⚠️');
						setDisableMessageButton(false);
						setPendingMessage(null);
					}
				}catch(err){
					setDisableMessageButton(false);
					alertToast('Failed to send message', '#cf4343', '⚠️')
					console.error("Failed to send text message", err);
					setPendingMessage(null);
				}
			}
		} catch(err){
			console.error("Failed to send a message in the sendMessage function in the conversation component:", err);
			alertToast('Failed to send message', '#cf4343', '⚠️');
			setDisableMessageButton(false);
			setPendingMessage(null);
		}
	};

	const handleTextAreaChange = (event) => {
		setNewMessage(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			sendMessage();
		}
	};

	const removeAttachment = (attachment) => {
		setAttachedFiles(prevFiles => prevFiles.filter(file => file !== attachment));
	};

	const onFileChange = (event) => {
		const newFiles = Array.from(event.target.files);
		setAttachedFiles(prevFiles => [...prevFiles, ...newFiles]);
		event.target.value = "";
	};

	const renderUserMessage = (message, messageIndex) => {
		return (
			<>
				{message.media && message.media.length > 0 && (
					<>
						<div className="message-row hotel-media-upload"> 
							<div className="spacer"></div>
							<div className="image-container-user">
								{message?.media?.map((mediaFile, mediaIndex) => (
									<div className="media-ctn" key={mediaIndex}>
										{mediaUrls && mediaUrls[mediaFile] && !loading ? (
											<img
												src={mediaUrls[mediaFile]}
												alt="media"
												style={{ width: '75%', height: '100%', margin: '10px 0px 0px 0px'}}
											/>
										) : (
											<div className="loading-ctn" id='hotel-media-upload'>
												<img 
													src={loadingSpinner} 
													style={{width: '50px', height: '50px'}} 
													onLoad={() => handleImageLoad(messageIndex, mediaIndex)} 
													className="loading-spinner"
												/>
												<div className="loading-label">Loading Media</div>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
						{!message.body || message.body === " " && (
							<>
								<div className="blue-timestamp">
									{handleIndividualTimestamp(message.dateCreated)}
								</div>
							</>
						)}
					</>
				)}
				{message.body && message.body !== ' ' && (
					<>
						<div className="message-row"> 
							<div className="spacer"></div>
							<div className="message from">{message.body}</div>
						</div>
						<div className="blue-timestamp">
							{handleIndividualTimestamp(message.dateCreated)}
						</div>
					</>
				)}
			</>
		);
	}
	

	
	const renderGuestMessage = (message, messageIndex) => {
		return (
			<>
				{message.media && message.media.length > 0 && (
					<>
						<div className="message-row guest-media-upload"> 
							<div className="image-container-guest">
								{message?.media?.map((mediaFile, mediaIndex) => (
									<React.Fragment key={mediaIndex}>
										{mediaUrls[mediaFile] && !loading ? (
											<img
												src={mediaUrls[mediaFile]}
												alt="media"
												style={{ width: '75%', height: '100%', margin: '10px 0px 0px 0px'}}
											/>
										) : (
											<div className="loading-ctn" id='guest-media-upload'>
												<img 
													src={loadingSpinner} 
													onLoad={() => handleImageLoad(messageIndex, mediaIndex)} 
													style={{width: '50px', height: '50px'}} 
													className="loading-spinner"
												/>
												
												<div className="loading-label">Loading Media</div>
											</div>
										)}
									</React.Fragment>
								))}
							</div>
						</div>
						{!message.body && (
							<>
								<div className="gray-timestamp">
									{handleIndividualTimestamp(message.dateCreated)}
								</div>
								
							</>
						)}
					</>
				)}
				{message.body && (
					<>
						<div className="message-row"> 
							<div className="message to">{message.body}</div>
							<div className="spacer"></div>
						</div>
						
						<div className="gray-timestamp">
							{handleIndividualTimestamp(message.dateCreated)}
						</div>
					</>
				)}
			</>
		);
	}
		
	
	const getMediaFroms3 = async(media) => {
		const response = await axios.get(`api/chat/get-media/${hotelId}/${media}`, {
			responseType: 'arraybuffer'
		});
		const blob = new Blob([response.data], {type: response.headers['content-type']});
		const imageUrl = URL.createObjectURL(blob);
		return imageUrl;
	}

	const handleAttachButtonClick = () => {
		document.getElementById("attach-input").click();
	};

	const handleImageLoad = (messageIndex, mediaIndex) => {
		mediaRef.current[messageIndex * 100 + mediaIndex] = true;
		const allImagesLoaded = mediaRef.current.every(Boolean);
		if (allImagesLoaded) {
		  scrollToBottom();
		}
		mediaRef.current[messageIndex * 100 + mediaIndex] = false;
	  };

	useEffect(() => {
		scrollToBottomSmooth();
		setMessages(conversation);
	},[conversation, pendingMessage]);

	useEffect(() => {
		const fetchMediaUrls = async () => {
			const newUrls = { ...mediaUrls };
			const promises = conversation?.map(async (message, messageIndex) => {
				if (Array.isArray(message.media)) {
					const mediaPromises = message.media.map(async (mediaFile, mediaIndex) => {
						if (!newUrls[mediaFile]) {
							const url = await getMediaFroms3(mediaFile);
							if (url) {
								newUrls[mediaFile] = url;
								mediaRef.current[messageIndex * 100 + mediaIndex] = false;
							}
						}
					});
					await Promise.all(mediaPromises);
				}
			});
			await Promise.all(promises);
			setMediaUrls(newUrls);
			setLoading(false);
		};
		fetchMediaUrls();
	}, [conversation]);

	useEffect(() => {
		const timer = setTimeout(() => {
			scrollToBottomSmooth();
		  }, 50);
		return () => {
			clearTimeout(timer);
		}
	}, [loading])

	return (
		<div className="conversation-body" >
			<div className="conversation-content-body">
				<div id="conversation-messages-row" className="conversation-messages-row">
					{conversation?.map((message, index) => (
						<div key={index}>
							{isMessageFromUser(message) ? renderUserMessage(message, index) : renderGuestMessage(message, index)}
						</div>
					))}
					{pendingMessage && (
						<>
							<div className="message-row"> 
								<div className="spacer"></div>
								<div className="pending-message-container">
									{pendingMessage?.media?.length > 0 && pendingMessage?.media?.map((media, index) => (
										<div key={index}>
											<div id="pending-message">{media?.name}</div>
										</div>
									))}
									<div id="pending-message">{pendingMessage.body}</div>
								</div>
							</div>
							<div className="pending-blue-timestamp" >
								{pendingMessage.status}...
							</div>
						</>
					)}
				</div>
				<div className="conversation-user-input">
					<div className="conversation-attachment-row">
						{attachedFiles.length > 0 && attachedFiles.map((file, index) =>
							<div className="attachment" key={index}>
								<span>{file.name}</span>
								<img
									className="trash-can-image"
									src={trashCan}
									onClick={() => removeAttachment(file)}
								></img>
							</div>
						)}
					</div>
					<div className="conversation-buttons-row">
						<input
							id="attach-input"
							type="file"
							accept="image/*"
							onChange={onFileChange}
							multiple
						/>
						<button className="attach-button">
							<img
								className="paper-clip-image"
								src={paperClip}
								onClick={handleAttachButtonClick}
							></img>
						</button>
						<div className="conversation-input-row">
							<textarea
								className="input"
								placeholder="Type here..."
								value={newMessage}
								onChange={handleTextAreaChange}
								onKeyDown={handleKeyDown}
							></textarea>
						</div>
						<button className="send-button" onClick={sendMessage} disabled={disableMessageButton}>
							Send
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Conversation;