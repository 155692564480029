import CheveronRight from '../../assets/cheveron-right.svg';
import CheveronLeft from '../../assets/cheveron-left.svg';

import { useState } from 'react';

import './style.scss';

export default function PaginationFooter({
    numberOfTotalPreviews = 300,
    currentPage = 1,
    setCurrentPage,
    numberOfPreviews = 1,
    pageSize,
}) {
    const [sumOfPreviews, setSumOfPreviews] = useState(11);

    const LIST_SIZE = 12;
    const numberOfPages = Math.ceil(numberOfTotalPreviews / pageSize);



    const generatePageNumbers = () => {
        const siblingRange = 2;
        const maxPaginationTabs = 5;
        const startPage = Math.max(1, currentPage - siblingRange);
        const endPage = Math.min(numberOfPages, currentPage + siblingRange);

        let pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        if (startPage > 1) {
            pages = [1, ...pages];
        }

        if (currentPage < numberOfPages) {
            pages = [...pages, 'ellipsis', numberOfPages];
        }

        if (currentPage > numberOfPages - 3) {
            return [...new Set(pages.filter((page) => page !== 'ellipsis'))];
        }

        return pages.slice(-maxPaginationTabs);
    };

    let totalShownPreviews = sumOfPreviews;

    if (totalShownPreviews > numberOfTotalPreviews) {
        totalShownPreviews = numberOfTotalPreviews;
    }

    let to_number = Math.min(currentPage*pageSize, numberOfTotalPreviews);
    let from_number = Math.min(((currentPage - 1)*pageSize) + 1,to_number);
    return (
        <div className='pagination-container'>
            <div>
                <span className='pagination-muted'>
                {`Showing ${currentPage === 0 ? 1 : from_number}
                    to ${to_number}
                    of ${numberOfTotalPreviews} results`}
                </span>
            </div>

            <ul className='pagination-list'>
                <li
                    className={currentPage === 1 ? 'pagination-list__arrow disabled' : 'pagination-list__arrow'}
                    onClick={() => {
                        if (currentPage === 1) return;
                        setCurrentPage(currentPage - 1);
                        setSumOfPreviews((prevState) => prevState - pageSize);
                    }}
                >
                    <img src={CheveronLeft} alt='cheveron-left' /> Previous
                </li>

                {generatePageNumbers().map((page, index) => (
                    <li
                        key={index}
                        onClick={() => {
                            if (page !== 'ellipsis') {
                                setCurrentPage(page);
                                setSumOfPreviews((page * pageSize) + 1);
                            }
                        }}
                        className={currentPage === page ? 'pagination-list__numbers active' : 'pagination-list__numbers'}
                    >
                        {page === 'ellipsis' ? '...' : page}
                    </li>
                ))}

                <li
                    className={currentPage === numberOfPages ? 'pagination-list__arrow disabled' : 'pagination-list__arrow'}
                    onClick={() => {
                        if (currentPage === numberOfPages) return;
                        setCurrentPage(currentPage + 1);
                        setSumOfPreviews((prevState) => prevState + pageSize);
                    }}
                >
                    <p className='floating-bar'></p>
                    Next <img src={CheveronRight} alt='cheveron-right' />
                </li>
            </ul>
        </div>
    );
}
