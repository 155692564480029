import React from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import ReviewsPanel from '../../components/ReviewsPanel/ReviewsPanel';

function ReviewsPage({ chatToken, hotelId, disabledPages, hotelData }) {
    return (
      <div className="arrivals-body">
        <ControlPanel page="reviews"  disabledPages={disabledPages}></ControlPanel>
        <ReviewsPanel hotelId={hotelId} hotelData={hotelData}></ReviewsPanel>
      </div>
    )
  }

export default ReviewsPage;