import React, { useState, useEffect } from 'react';
import './style.scss';
import axios from 'axios';
import Table from '../Table/Table';
import Snapshot from '../Snapshot/Snapshot';

const ReviewsPanel = ({hotelId, hotelData}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [avgReviewScore, setAvgReviewScore] = useState(0);
    const [typeFormsData, setTypeFormsData] = useState([]);
    const [feedbackCount, setFeedbackCount] = useState(0);
    const [avg3MonthReviewScore, setAvg3MonthReviewScore] = useState(0);
    const [feedbackOrReviews, setFeedbackOrReviews] = useState(false);
    const [newReviewsObject, setNewReviewsObject] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [selectedReview, setSelectedReview] = useState("Reviews");

    const checkIfMultipleBusinesses = async () => {
        const businesses = hotelData?.Businesses;
        if(businesses && businesses.length > 1){
            setBusinesses(businesses);
        }
    };

    const convertReviewsObject = () => {
        const reviewObjects = Object.values(reviews).filter(review => typeof review === 'object');
        const newFeedbackObjects = reviewObjects.map(review => {
            const { review: feedback, name, timestamp, ...rest } = review;
            const nameSplit = name ? name.split(' ') : ['', ''];
            const firstName = nameSplit[0];
            const lastName = nameSplit.slice(1).join(' ');
            const dateObject = new Date(timestamp);
            const date = dateObject.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
            return { ...rest, feedback, firstName, lastName, date };
        });
        setNewReviewsObject(newFeedbackObjects);
    };

    const getSideChatLockStatus = async() => {
        const url = "/api/reviews/getSideChatDisableFeatureStatus";
        try{
            const response = await axios.get(url, {
                params: {
                    hotelId
                },
            });
            const result = response?.data?.data;
            localStorage.setItem("review_response_disabled", result);
        }catch(err){
            console.error("Failed to get the side chat lock status", err);
        }
    };

    const extractNumerator = (rating) => {
        const regex = /^(\d+(\.\d+)?)\/(5|10)$/;
        const match = rating.match(regex);
        if (match) {
            let numerator = parseFloat(match[1]);
            const denominator = parseInt(match[3], 10);
            if (denominator === 10) {
                numerator = numerator / 2;
            }
            return numerator;
        }
        return 0;
    };

    const getGoogleReviews = async() => {
        try{
            axios.get(`/api/getReviews/${hotelId}/Reviews`, )
                .then(response => {
                    setReviews(response.data);
                    toggleFeedbackOrReviews(true);

                    getHotelReviewAvg();
                })
                .catch(error => {
                    console.error('Error fetching reviews:', error);
                });
                toggleFeedbackOrReviews(true);
        } catch(err){
            console.error("Error in getGoogleReviews: ",err)
        }
    };

    const getTypeforms = async() => {
        const TypeFormData = "TypeFormData";
        try{
            const response = await axios.get(`/api/typeform/getTypeforms/${hotelId}/${TypeFormData}`);
            const typeFormsData = response.data || {};
            const allFormsData = []
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
            const recentTypeFormsData = typeFormsData.filter(typeForm => {
              const timestamp = new Date(typeForm.timestamp);
              return timestamp >= oneMonthAgo;
            });
            recentTypeFormsData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            const filteredData = recentTypeFormsData.filter(typeForm => {
                const rating = typeForm?.questions[0]?.response;
                return Number(rating) < 4;
            })
            filteredData.forEach(typeForm => {
                const timestamp = typeForm.timestamp;
                const date = new Date(timestamp);
                const readableFormat = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
                typeForm.timestamp = readableFormat;
                allFormsData.push(typeForm);
            });
            setTypeFormsData(allFormsData);
        } catch(err){
            console.error("Error fetching typeforms: ", err);
        }
    };

    const toggleFeedbackOrReviews = (isReviews) => {
        setFeedbackOrReviews(isReviews);
    };

    const getHotelReviewAvg = async() => {
        const averageReviewScore = await axios.get(`api/getOverallReviewScore/${hotelId}`);
        if(averageReviewScore.data.OverallReviewScore){
            setAvgReviewScore(averageReviewScore.data.OverallReviewScore);
        }else{
            setAvgReviewScore(0);
        }

    };

    const getReviewAvgForLast3Months = (reviews) => {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        const recentReviews = reviews.filter(review => {
            const reviewDate = new Date(review.timestamp);
            return reviewDate >= threeMonthsAgo
        })
        let totalScore = 0;
        setAvg3MonthReviewScore(0);
        recentReviews.forEach(review => {
            let numerator = extractNumerator(review.rating);
            if(!review.rating.includes('/')){
                numerator = review.rating
            }
            if (numerator > 0) {
                totalScore += Number(numerator);
            }
        });
        const avgScore = totalScore / recentReviews.length;
        setAvg3MonthReviewScore(avgScore.toFixed(1));
    };

    const getHotelFeedbackFormsCountForPastMonth = () => {

        try{
            const rightNow = new Date()
            const oneMonthAgo = new Date(new Date().setDate(rightNow.getDate() - 31));
            const oneMonthAgoFormatted = new Date(oneMonthAgo.toISOString().slice(0,10));
            const feedbackCount = typeFormsData.filter(form => {
                const formDate = new Date(form.timestamp);
                return formDate >= oneMonthAgoFormatted && formDate <= rightNow;
            }).length
            setFeedbackCount(feedbackCount);
        }catch(error){
            console.error('Error fetching feedback forms:', error);
            setFeedbackCount(0);
        }
    };
    
    const whichReviewsToDisplay = async (reviewType = 'Reviews') => {
        try {
            const response = await axios.get(`/api/getReviews/${hotelId}/${reviewType}`);
            setReviews(response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching filtered reviews:', error);
            return [];
        }
    };

    const handleBusinessChange = async (event) => {
        const selectedReview = event.target.value;
        setSelectedReview(selectedReview);
        const reviewsData = await whichReviewsToDisplay(selectedReview);
        const avgScore = getReviewAvgForLast3Months(reviewsData);
        setAvg3MonthReviewScore(avgScore);
    };

    const displaySnapshots = () => {
        switch(selectedReview){
            case 'Reviews':
                return(
                    <>
                        <div className="reviews-snapshot-container">
                            <Snapshot title={"Overall Score"} content={`${businesses[0]?.Overall_Review_Score} ★`} ></Snapshot>
                            <Snapshot title={"3 Month Avg"} content={`${avg3MonthReviewScore} ★`}></Snapshot>
                            <Snapshot title={"Feedback Forms"} content={`${feedbackCount}/month`}></Snapshot>
                        </div>
                    </>
                )
            case 'Reviews1':
                return(
                    <>
                        <div className="reviews-snapshot-container">
                            <Snapshot title={"Overall Score"} content={`${businesses[1]?.Overall_Review_Score} ★`} ></Snapshot>
                            <Snapshot title={"3 Month Avg"} content={`${avg3MonthReviewScore} ★`}></Snapshot>
                            <Snapshot title={"Feedback Forms"} content={`${feedbackCount}/month`}></Snapshot>
                        </div>
                    </>
                )
            case 'Reviews2':
                return(
                    <>
                        <div className="reviews-snapshot-container">
                            <Snapshot title={"Overall Score"} content={`${businesses[2]?.Overall_Review_Score} ★`} ></Snapshot>
                            <Snapshot title={"3 Month Avg"} content={`${avg3MonthReviewScore} ★`}></Snapshot>
                            <Snapshot title={"Feedback Forms"} content={`${feedbackCount}/month`}></Snapshot>
                        </div>
                    </>

                )
            default:
                return null;
        }
    };
    
    useEffect(() => {
        getGoogleReviews();
        getTypeforms();
        checkIfMultipleBusinesses();
    },[]);

    useEffect(() => {
        getHotelFeedbackFormsCountForPastMonth();
    }, [typeFormsData]);

    useEffect(() => {
        convertReviewsObject();
        getReviewAvgForLast3Months(reviews);
    }, [reviews]);

    useEffect(() => {
        getHotelReviewAvg();
    },[businesses]);

    useEffect(() => {
        getSideChatLockStatus();
    },[]);
    
    useEffect(() => {
        setIsLoading(true)
        whichReviewsToDisplay(selectedReview).then(reviewsData => {
            const avgScore = getReviewAvgForLast3Months(reviewsData);
            setAvg3MonthReviewScore(avgScore);
            setIsLoading(false);
        });
    }, [selectedReview]);


    return(
        <>
            <div className="reviews-panel-body">
                <div className="reviews-panel-content">
                    <div className='reviews-panel-header'>
                        <div className="reviews-panel-title">Reviews</div>
                    </div>
                    {businesses && businesses.length > 1 ? (
                        <>
                            {isLoading ? <></> : displaySnapshots()}    
                        </>
                    ) : (
                        <div className="reviews-snapshot-container">
                            <Snapshot title={"Overall Score"} content={`${avgReviewScore} ★`} />
                            <Snapshot title={"3 Month Avg"} content={`${avg3MonthReviewScore} ★`} />
                            <Snapshot title={"Feedback Forms"} content={`${feedbackCount}/month`} />
                        </div>
                    )}
                    {businesses && businesses.length > 1 && (
                        <>
                             <select className='business-list-container' onChange={handleBusinessChange}>
                                {businesses.map((business, index) => (
                                    <>
                                        <option key={index} value={business?.review} >
                                            {business.business_name}
                                        </option>
                                    </>
                                ))}
                            </select>
                        </>
                    )}
                    <div className="reviews-button-row">
                        <div onClick={() => toggleFeedbackOrReviews(true)} className={`reviews-menu-button ${feedbackOrReviews ? 'active' : ''}`}>Google Reviews</div>
                        <div onClick={() => toggleFeedbackOrReviews(false)} className={`feedback-menu-button ${feedbackOrReviews ? '' : 'active'}`}>Feedback Forms</div>
                    </div>
                    {feedbackOrReviews ? (
                        <>
                            <Table group={'reviews'} data={newReviewsObject} hotelId={hotelId} hotelData={hotelData}></Table>
                        </>
                    ) : (
                        <>
                            <Table group={'feedback'} data={typeFormsData} hotelId={hotelId}></Table>
                        </>
                    )}
                
                </div>
            </div>
        </>
    )
}

export default ReviewsPanel;


