import React from 'react';
import moment from 'moment';
import './style.scss';
import axios from "axios";
import toast from 'react-hot-toast';

class CustomMessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      arrivalDate: '',
      departureDate:'',
      messageContent: '',
      arrivalInputType: 'text',
      departureInputType: 'text',
      isSendButtonDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleArrivalDateFocus = () => {
    this.setState({ arrivalInputType: 'date' });
  };

  handleArrivalDateBlur = () => {
    this.setState({ arrivalInputType: 'text' });
  };

  handleDepartureDateFocus = () => {
    this.setState({ departureInputType: 'date' });
  };

  handleDepartureDateBlur = () => {
    this.setState({ departureInputType: 'text' });
  };

  handleChange(event) {
    const { name, value } = event.target;
  
    // If the field is 'phoneNumber', sanitize the input
    if (name === 'phoneNumber') {
      // Use a regular expression to keep only digits and certain characters
      const sanitizedValue = value.replace(/[^\d+() \-]/g, '');

      // Update the state with the sanitized and formatted value
      this.setState({ [name]: sanitizedValue });
    } else {
      // For other fields, update the state as usual
      this.setState({ [name]: value });
    }
  }

  sanitizePhoneNumber(input) {
    // Remove any non-digit, non-plus characters
    const sanitizedValue = input.replace(/[^+\d]/g, '');
  
    // Ensure +1 at the beginning if not already there
    const formattedValue = sanitizedValue.startsWith('+') ? sanitizedValue : `+1${sanitizedValue}`;
  
    return formattedValue;
  }

  convertDate(dateString) {
    if (dateString) {
      const formattedDate = moment(dateString).format("MM/DD/YYYY");
      return formattedDate
    } else {
      return '';
    }
  }

  alertToast = (message, color, icon, duration = 3000) => {
    const id = toast((t) => (
    <div>
        <span onClick={() => toast.dismiss(t.id)}>
        {message}
        </span>
    </div>
    ), {
    position: 'top-right',
    icon: icon,
    duration: duration,
    style: {
        background: color,
        color: '#ffffff',
    }
    });
    return id;
}

  async handleSubmit(event) {
    this.setState({isSendButtonDisabled: true})
    event.preventDefault();
    
    try {
      const hotelId = localStorage.getItem('marie_hotel_id')
      const sanitizedPhoneNumber = this.sanitizePhoneNumber(this.state.phoneNumber)
      this.alertToast(`Sending message to ${sanitizedPhoneNumber}`, '#01a0c6', '🚀', 3000);
      const addGuestBody = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: sanitizedPhoneNumber,
        arrival_message: this.state.arrivalDate,
        departure_message: this.state.departureDate,
        checkinDate: this.state.arrivalDate,
        checkoutDate: this.state.departureDate
      }
      if(!addGuestBody.phoneNumber){
        this.alertToast("Please add a phone number", "#cf4343", "⚠️");
        this.setState({ isSendButtonDisabled: false });
        return;
      }
      const getGuestResponse = await axios.get(`/api/guests/get-by-phone-number/${hotelId}/${addGuestBody.phoneNumber}`);
      if(!getGuestResponse.data){
        const addGuestResponse = await axios.post(`/api/guests/${hotelId}/add-single-guest`, addGuestBody)
      }
      const hotelPhoneNumber = localStorage.getItem('marie_hotel_phone_number')
      const initConversationBody = {
				hotelId: hotelId, 
				guestPhoneNumber: sanitizedPhoneNumber, 
				hotelPhoneNumber: hotelPhoneNumber, 
				messageContent: this.state.messageContent
			}
      if(!initConversationBody.messageContent){
        this.alertToast("Message cannot be blank", "#cf4343", "⚠️");
        this.setState({ isSendButtonDisabled: false });
        return;
      }
      const initConversationResponse = await axios.post("/api/chat/send-message", initConversationBody);
      this.props.onFormSent();
      this.alertToast(`Message sent`, '#01a0c6', '✓', 3000);
    } catch (error) {
      console.error('there was an error sending the message', error)
      this.alertToast("Unable to send message", '#cf4343', '⚠️')
    }
    this.setState({isSendButtonDisabled: false})
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="input-wrapper">
          <input 
            type="text"
            name="firstName" 
            value={this.state.firstName} 
            onChange={this.handleChange} 
            placeholder="Guest First Name" />
          <input 
            type="text" 
            name="lastName"
            value={this.state.lastName} 
            onChange={this.handleChange} 
            placeholder="Guest Last Name" />
        </div>
        <div className="input-wrapper">
          <input 
            type="tel" 
            value={this.state.phoneNumber}
            name="phoneNumber"
            onChange={this.handleChange} 
            placeholder="Guest Phone Number" />
        </div>
        <div className="input-wrapper">
          <input 
            placeholder="Arrival Date"
            type={this.state.arrivalInputType}
            onFocus={this.handleArrivalDateFocus}
            onBlur={this.handleArrivalDateBlur}
            name="arrivalDate"
            value={this.state.arrivalInputType == 'date' ? this.state.arrivalDate : this.convertDate(this.state.arrivalDate)}
            onChange={this.handleChange} />
          <input 
            placeholder="Departure Date"
            type={this.state.departureInputType}
            onFocus={this.handleDepartureDateFocus}
            onBlur={this.handleDepartureDateBlur}
            name="departureDate"
            value={this.state.departureInputType == 'date' ? this.state.departureDate : this.convertDate(this.state.departureDate)} 
            onChange={this.handleChange} />
        </div>
        <div className="input-wrapper textarea-wrapper">
          <textarea
            name="messageContent" 
            id="message-text"
            value={this.state.messageContent} 
            onChange={this.handleChange} 
            placeholder="Write message here">
          </textarea>
        </div>
        <div className="submit-wrapper">
          {/* TODO: Finish this file input stuff */}
          <div className="spacer"></div>
          <input type="submit" value="Send" disabled={this.state.isSendButtonDisabled}/>
        </div>
      </form>
    );
  }
}

export default CustomMessageForm