import { useState, useEffect } from "react";
import createContainer from "./CreateContainer";

import { createPortal } from "react-dom";

import Checkmark from "../../../assets/checkmark-icon.png";

const container = createContainer();

let timeToDelete = 300;
let timeToClose = 500 * 10;

export default function Notification({
  color = Color.info,
  autoClose = false,
  onDelete,
  children,
}) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onDelete]);

  useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoClose]);

  return createPortal(
    <div className={`notification-container ${isClosing ? 'notification-shrink' : ''}`}>
      <div
        className={`notification ${color} ${isClosing ? 'notification-slideOut' : 'notification-slideIn'}`}
      >
        {children}
        <button
          onClick={() => setIsClosing(true)}
          className="closeButton"
        >
          <img src={Checkmark} alt="Checkmark" height={16} />
        </button>
      </div>
    </div>,
    container
  );
}

export const Color = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};