import React from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import LeadsPanel from '../../components/LeadsPanel/LeadsPanel';

function LeadsPage({ chatToken, hotelId, disabledPages }) {
    return (
      <div className="arrivals-body">
        <ControlPanel page="leads" disabledPages={disabledPages}></ControlPanel>
        <LeadsPanel hotelId={hotelId}></LeadsPanel>
      </div>
    )
  }

export default LeadsPage;