import React, { useState, useEffect, useRef } from 'react';
import './style.scss';

const Modal = ({ showModal, closeModal, children }) => {
  const [isVisible, setIsVisible] = useState(showModal);
  const modalRef = useRef(null);

  useEffect(() => {
    setIsVisible(showModal);
  }, [showModal]);

  const handleCloseModal = () => {
    setIsVisible(false);
    closeModal();
  };

  const handleOverlayClick = (event) => {
    // Check if the click occurred outside the modal (on the overlay)
    if (event.target === event.currentTarget) {
      handleCloseModal();
    }
  };

  const handleModalClick = (event) => {
    // Stop propagation to prevent closing the modal when clicking inside it
    event.stopPropagation();
  };

  return (
    <>
      {isVisible && (
        <div className="modal-overlay" >
          <div className="modal" onClick={handleOverlayClick}>
            <div className="modal-body" onClick={handleModalClick}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;