import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import smilingWoman from '../../assets/smiling-woman.png'
import inboxIcon from '../../assets/inbox-icon.png'
import guestConversationsIcon from '../../assets/guest-conversations-icon.png'
import reviewsIcon from '../../assets/reviews-icon.png'
import arrivalsIcon from '../../assets/arrivals-icon.png'
import settingsIcon from '../../assets/settings-icon.png'
import leadsIcon from '../../assets/leads-icon.png'

import Lock from "../../assets/lock.png";

import axios from "axios";
import './style.scss';
import PopupTextBox from '../PopupTextBox/PopupTextBox';

function ControlPanel({ page, disabledPages }) {
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(true);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  return (
    <div className="control-panel-body">
      <div className="control-panel">
        <img id="smiling-woman" src= {smilingWoman}></img>
        <div className="control-panel-header-text">
        </div>
      </div>
      <Link to="/requests" className={`nav-button ${page === 'requests' ? 'is-current-page' : ''}`}>
        <div className="nav-button-icon-container">
          <img className="nav-button-icon" src={inboxIcon}></img>
        </div>
        <div className="nav-button-name-container">
          <div className="nav-button-name-text">Inbox</div>
        </div>
      </Link>
      <Link to="/inbox" className={`nav-button ${page === 'inbox' ? 'is-current-page' : ''}`}>
        <div className="nav-button-icon-container">
          <img className="nav-button-icon" src={guestConversationsIcon}></img>
        </div>
        <div className="nav-button-name-container">
          <div className="nav-button-name-text">Guest Conversations</div>
        </div>
      </Link>
        {disabledPages?.leadsPageDisabled ? (
          <>

            <div className={`nav-button disabled`}>
              <div className="nav-button-icon-container">
                <img className="nav-button-icon" src={leadsIcon} alt="Reviews Icon" />
              </div>
              <div className="nav-button-name-container">
                <div className="nav-button-name-text">Leads</div>
              </div>
              <div className="nav-button-disable-icon-container" onMouseEnter={() => setIsPopupVisible(true)} onMouseLeave={() => setIsPopupVisible(true)}>
                <img className="nav-button-disable-icon" src={Lock} alt="Disable Icon"/>
              </div>
              {isPopupVisible && (
                <div className="popup-textbox-container" ref={popupRef}>
                  <PopupTextBox message={"This Feature is Locked."} linkUrl={"mailto:sales@talsey.com"} linkText={"Learn More"}/>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Link to="/leads" className={`nav-button ${page === 'leads' ? 'is-current-page' : ''}`}>
              <div className="nav-button-icon-container">
                <img className="nav-button-icon" src={leadsIcon}></img>
              </div>
              <div className="nav-button-name-container">
                <div className="nav-button-name-text">Leads</div>
              </div>
            </Link>
          </>
        )}


      {disabledPages?.reviewsPageDisabled  ? (
        <div className={`nav-button ${page === 'reviews' ? 'is-current-page' : ''} disabled`}>
          <div className="nav-button-icon-container">
            <img className="nav-button-icon" src={reviewsIcon} alt="Reviews Icon" />
          </div>
          <div className="nav-button-name-container">
            <div className="nav-button-name-text">Reviews</div>
          </div>
          <div className="nav-button-disable-icon-container" onMouseEnter={() => setIsPopupVisible(true)} onMouseLeave={() => setIsPopupVisible(true)}>
            <img className="nav-button-disable-icon" src={Lock} alt="Disable Icon"/>
          </div>
          {isPopupVisible && (
            <div className="popup-textbox-container" ref={popupRef}>
              <PopupTextBox message={"This Feature is Locked."} linkUrl={"mailto:sales@talsey.com"} linkText={"Learn More"}/>
            </div>
          )}
        </div>
      ) : (
        <Link to="/reviews" className={`nav-button ${page === 'reviews' ? 'is-current-page' : ''}`}>
          <div className="nav-button-icon-container">
            <img className="nav-button-icon" src={reviewsIcon} alt="Reviews Icon" />
          </div>
          <div className="nav-button-name-container">
            <div className="nav-button-name-text">Reviews</div>
          </div>
        </Link>
      )}
      <Link to="/arrivals" className={`nav-button ${page === 'arrivals' ? 'is-current-page' : ''}`}>
        <div className="nav-button-icon-container">
          <img className="nav-button-icon" src={arrivalsIcon}></img>
        </div>
        <div className="nav-button-name-container">
          <div className="nav-button-name-text">Arrivals</div>
        </div>
      </Link>
      <Link to="/settings" className={`nav-button ${page === 'settings' ? 'is-current-page' : ''}`}>
        <div className="nav-button-icon-container">
          <img className="nav-button-icon" src={settingsIcon}></img>
        </div>
        <div className="nav-button-name-container">
          <div className="nav-button-name-text">Settings</div>
        </div>
      </Link>
      <div className="spacer"></div>
    </div>
  );
}

export default ControlPanel;