import React from 'react';
import './style.scss';

const PopupTextBox = ({ message, linkText, linkUrl }) => {
    
  return (
    <div className="popup-textbox-container">
      <div className="popup-textbox">
        <p>{message}</p>
        <a href={`${linkUrl}`}>{linkText}</a>
      </div>
    </div>
  );
};

export default PopupTextBox;