import React, { useState, useEffect, useRef } from 'react';
import TableRow from '../TableRow/TableRow';
import PaginationFooter from '../PaginationFooter/PaginationFooter';
import './style.scss'


const Table = ({ group, data, hotelId, hotelData }) => {
    const [currentPage, setCurrentPages] = useState(1);
    const [currentData, setCurrentData] = useState({});
    const [listSize, setListSize] = useState(10);
	const containerRef = useRef(null);
    const totalItems = data?.length;

    const createDataFormat = (currentPage) => {
        const startIndex = (currentPage - 1) * listSize;
        const endIndex = currentPage * listSize;
        return Object.keys(data)
            .slice(startIndex, endIndex)
            .reduce((acc, key) => {
                acc[key] = data[key];
                return acc;
            }, {});
    };

    useEffect(() => {
        setCurrentData(createDataFormat(currentPage));
    }, [currentPage, data]);

    useEffect(() => {
        setCurrentPages(1);
    }, [group]);

    useEffect(() => {
        const adjustListSize = () => {
            if (containerRef.current) {
                const containerHeight = containerRef.current.clientHeight;
                const rowHeight = 55;
                const footerHeight = 35;
                const availableHeight = containerHeight - footerHeight;
                const rowsVisible = Math.floor(availableHeight / rowHeight);
                setListSize(rowsVisible);
            }
        };
        adjustListSize();
        window.addEventListener('resize', adjustListSize);
        return () => {
            window.removeEventListener('resize', adjustListSize);
        };
    }, []);
    // console.log("currentPage", (currentPage) * listSize)
    return (
        <div className='table-body' ref={containerRef}>
            <div className='table-component-ctn'>
                {Object.entries(currentData).map(([key, item]) => (
                    <div className="table-row-ctn" key={key}>
                        <TableRow
                            group={group}
                            data={item}
                            hotelId={hotelId}
                            hotelData={hotelData}
                        />
                    </div>
                ))}
            </div>
            <PaginationFooter
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPages(page)}
                numberOfTotalPreviews={totalItems}
                numberOfPreviews={listSize}
                pageSize={listSize}
            />
        </div>
    );
};

export default Table;