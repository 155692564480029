import React, { useEffect, useState, useRef } from "react";
import PaginationFooter from "../PaginationFooter/PaginationFooter";
import loadingSpinner from "../../assets/eclipse-1s-200px.gif";
import Inbox from "./Inbox/Inbox";
import "./style.scss";
import axios from "axios";
import SideChat from "../SideChat/SideChat";
import { useSearchParams } from "react-router-dom";


const InboxPanel = ({token, hotelId, arrivalData, socket}) => {
	const [conversations, setConversations] = useState([]);
	const [conversationPreviews, setConversationPreviews] = useState([]);
	const [conversationsLoaded, setConversationsLoaded] = useState(false);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [currentGuestPhoneNumber, setCurrentGuestPhoneNumber] = useState(null);
	const [manualConversationIds, setManualConversationIds] = useState([]);
	const [manualTimeouts, setManualTimeouts] = useState([]);
	const [showBlockOverlay, setShowBlockOverlay] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [isSideChatOpen, setIsSideChatOpen] = useState(false);
	const [sideChatData, setSideChatData] = useState(null);
	const [isBlocked, setIsBlocked] = useState(false);
	const [urlParams, setUrlParams] = useSearchParams();
	const [listSize, setListSize] = useState(12);
	const containerRef = useRef(null);

	const getUniqueConversationPreviews = (conversationPreviews) => {
		const seen = {};

		return conversationPreviews.filter(function (item) {
			return seen.hasOwnProperty(item.phoneNumber) ? false : (seen[item.phoneNumber] = true);
		});
	}
	
	const checkForSpecifiedConversation = async (id) => {
		const conversation = await getConversationFromPhoneNumber(id);
		if (conversation) {
			const name = conversation.Name?.First + " " + conversation.Name?.Last ?? "Guest";
			displayConversation(id, name, id, null);
		}
	};

	const getConversationFromPhoneNumber = async (guestPhoneNumber) => {
		try {
			guestPhoneNumber = guestPhoneNumber.replace(/\s/g, "");
			const guestConversation = await axios.get(`/api/guests/get-by-phone-number/${hotelId}/${guestPhoneNumber}`);
			return guestConversation.data;
		} catch (error) {
			console.error(`Error encountered in getConversationFromPhoneNumber: ${error}`);
		}
	};

	const adjustManualTimeouts = (conversationId, shouldAdd, timestamp) => {
		if (shouldAdd) {
			const now = new Date().getTime();
			const difference = timestamp - now;
			const timeoutId = setTimeout(() => {
				removeFromManualConversations(conversationId);
			}, difference);

			setManualTimeouts((prevManualTimeouts) => [
				...prevManualTimeouts,
				{
					conversationId: conversationId,
					timeoutId: timeoutId,
				},
			]);
		} else {
			const pairing = manualTimeouts.find((pairing) => pairing.conversationId === conversationId);
			if (pairing) {
				setManualConversationIds((prev) => {
					prev.manualTimeouts.filter((pairing) => pairing.conversationId !== conversationId);
				})
				clearTimeout(pairing.timeoutId);
			}
		}
	};

	const updateManualTimeoutEntryInDatabase = async (hotelId, guestPhonenumber, timestamp) => {
		const response = await axios
			.post("/api/chat/set-to-manual", {
				hotelId: hotelId,
				guestPhoneNumber: guestPhonenumber,
				timestamp: timestamp.toString(),
			});
	};

	const addToManualConversations = (conversationId, timestamp) => {
		setManualConversationIds((prevManualConversationIds) => [
			...prevManualConversationIds,
			conversationId
		]);
		adjustManualTimeouts(conversationId, true, timestamp);
	};

	const removeFromManualConversations = (conversationId) => {
		setManualConversationIds(manualConversationIds.filter((id) => id !== conversationId));
		adjustManualTimeouts(conversationId, false, null);
	};

	const toggleManualSwitch = (event) => {
		const shouldAdd = event.target.checked;
		if (shouldAdd) {
			const timestamp = new Date().getTime() + 1 * 60 * 1000 * 15; // TODO: make configurable
			addToManualConversations(currentConversation.id, timestamp);
			updateManualTimeoutEntryInDatabase(hotelId, currentGuestPhoneNumber, timestamp);
		} else {
			removeFromManualConversations(currentConversation.sid);
			const now = new Date().getTime();
			updateManualTimeoutEntryInDatabase(hotelId, currentGuestPhoneNumber, now);
		}
	};

	const isInManualMode = async() => {
		const result = await axios.get(`/api/chat/manual-status/${hotelId}/${currentGuestPhoneNumber}`).then(res => { return res.data.manualEndsTimestamp});
		const now = Date.now();
		return result > now;
	};

	const setSearchParams = (key, value) => {
		var searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);
		var newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
		window.history.pushState(null, "", newRelativePathQuery);
	};
	const closeSideChat = () => {
		setIsSideChatOpen(false);
		setSideChatData(null);
		const searchParams = new URLSearchParams(window.location.search);
		if(searchParams.has('guestPhoneNumber')){
			searchParams.delete('guestPhoneNumber');
			const newRelativePathQuery = window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '');
			window.history.replaceState(null, '', newRelativePathQuery);
		}
	};
	const displayConversation = async (id, name, phoneNumber) => {
		const currentConversation = conversations.find((conversation) => conversation.id === phoneNumber);
		if(currentConversation){
			setSearchParams("guestPhoneNumber", phoneNumber);
			const allConversationContent = {
				...currentConversation,
				guestName: name,
				guestPhoneNumber: phoneNumber,
				id: id,
			};
			setCurrentConversation(currentConversation);
			setCurrentGuestPhoneNumber(phoneNumber);
			setSideChatData(allConversationContent);
			setIsSideChatOpen(true);
			const isBlocked = await getBlockedStatus(phoneNumber);
			setIsBlocked(isBlocked)
		}
	};

	const onBackButtonClicked = () => {
		setCurrentConversation(null);
		window.history.pushState(null, "", window.location.pathname);
	};

	const handleBlockUnblockClick = (event) => {
		event.preventDefault();
		const guestPhoneNumber = currentGuestPhoneNumber;
		if (guestPhoneNumber) {
			if (isBlocked) {
				return unblockUser(guestPhoneNumber);
			} else {
				return blockUser(guestPhoneNumber);
			}
		}
	};

	const blockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/block", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: hotelId,
			});
			if (response.status === 200) {
				setIsBlocked(true);
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
			console.error("Failed to block user", error);
        	return false;
		}
	};

	const unblockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/unblock", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: hotelId,
			});
			if (response.status === 200) {
				setIsBlocked(false);
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
			console.error("Failed to unblock user", error);
        	return true;
		}
	};

	const getBlockedStatus = async (guestPhoneNumber) => {
		try {
			const response = await axios.get("api/chat/blocked-status", {
				params: {
					guestPhoneNumber: guestPhoneNumber,
					hotelId: hotelId,
				},
			});
			if (response.status === 200) {
				return response.data.blocked;
			} else {
				return false;
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	// method to toggle the block overlay
	const toggleBlockOverlay = () => {
		setShowBlockOverlay(!showBlockOverlay);
	};

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
		  var intlCode = (match[1] ? '+1 ' : '');
		  return ['(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return phoneNumberString;
	  }
	
	const generateBody = (messageBody) => {
		if (!messageBody || messageBody === '"undefined"') return ('')
		if (messageBody.length > 100) {
			const shortString = messageBody.slice(0,99)
			return shortString + '...'
		} else {
		  	return messageBody
		}
	}
	const setNewCurrentPage = (page) => {
		setCurrentPage(page);
	}

	useEffect(() => {		
		const handleWebsocketMessage = async (message) => {
			try{
				switch(message.type){
					case "connectionSuccess":
						console.log("Websocket Connected");
						break;
					case "setConversationPreviews":
						console.log("setting conversationPreviews");
						setConversationsLoaded(true);
						setConversationPreviews(message.conversationPreviews);
						break;
						case "setConversations":
							console.log("setting conversations");
							setConversations(message.conversations);
							setCurrentConversation(message?.conversations?.filter(conversation => conversation?.id === currentGuestPhoneNumber));
							setConversationsLoaded(true);
							break;
						case "setGuestConvos":
							console.log("setting conversations and conversationPreviews");
							message.conversationPreviews.sort((a, b) => {
								if(a.timestamp === "n/a") return 1;
								if(b.timestamp === "n/a") return -1;
								const dateA = new Date(a.timestamp);
								const dateB = new Date(b.timestamp);
								return dateB - dateA;
							});
							setConversationPreviews(message.conversationPreviews);
							setConversations(message.conversations);
							setConversationsLoaded(true);
							break;
					default:
						console.error("Unknown websocket message");
				}
			}catch(error){
				console.error("Error in handleWebsocketMessage:", error);
			}
		}
	
		socket?.on('client', (message) =>{
			console.log("Getting message");
			handleWebsocketMessage(message);
		})

		socket?.emit("server", {
			type: "getGuestConvos"
		});
		



		// Check blocked status after initializing conversations
		if (currentGuestPhoneNumber) {
			getBlockedStatus(currentGuestPhoneNumber);
		}

		// Assume inbox is empty if we haven't grabbed a conversation in 7 seconds
		const timeoutId = setTimeout(() => {
			setConversationsLoaded(true);
		}, 7000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);
	const formatTimestamp = (timestamp) => {
		const date = new Date(timestamp);
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const dateFormatter = new Intl.DateTimeFormat('en-US', {
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
			timeZone: timeZone
		});
		const formattedDate = dateFormatter.format(date);
		return formattedDate
	}
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('guestPhoneNumber')) {
			let param = urlParams.get("guestPhoneNumber");
			if(param && !currentGuestPhoneNumber && param !== currentGuestPhoneNumber){
				urlParams.set("guestPhoneNumber", currentGuestPhoneNumber);
				const newRelativePathQuery = window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '');
				window.history.replaceState(null, '', newRelativePathQuery);
				checkForSpecifiedConversation(param);
			}
		}
	},[currentGuestPhoneNumber]);

	useEffect(() => {
        const adjustListSize = () => {
            if (containerRef.current) {
                const containerHeight = containerRef.current.clientHeight;
                const rowHeight = 50;
                const footerHeight = 75;
                const availableHeight = containerHeight - footerHeight;
                const rowsVisible = Math.floor(availableHeight / rowHeight);
				console.log("what is the rowsVisible?", rowsVisible)
                setListSize(rowsVisible);
            }
        };

        adjustListSize();
        window.addEventListener('resize', adjustListSize); // Update on window resize

        return () => {
            window.removeEventListener('resize', adjustListSize);
        };
    }, []);

	return (
		<div className="inbox-panel-body">
			<div className="inbox-panel-content">
				<div className="inbox-panel-header">
					<div>
						<div className="inbox-panel-title">Guest Conversations</div>
					</div>
				</div>
				<div id="inbox-conversation-container" className={(currentConversation == null) ? "inbox-container" : "conversation-container"} ref={containerRef}>
					<div>
						<div className="inbox-body">
							<div className="inbox-content-body">
								{!conversationsLoaded ? 
									<div className="loading-container">
										<img className="loading-spinner" src={loadingSpinner}></img>
									</div>
								: conversationPreviews
								.slice((currentPage - 1) * listSize, currentPage * listSize)
								.map((conversationPreview, index) =>
									<div className="message-preview-centering-container" key={index}>
										<div className={conversationPreview.unread ? "message-preview-body" : "message-preview-body read"} onClick={() => displayConversation(conversationPreview.phoneNumber, conversationPreview.name, conversationPreview.phoneNumber)}>
											<div className= "guest-conversations-name-container">
											<span className="guest-conversations-name">{conversationPreview.name}</span>
											</div>
											<div className= "guest-conversations-phone-container">
											<span className="guest-conversations-phone-number">{formatPhoneNumber(conversationPreview.phoneNumber)}</span>
											</div>
											<div className= "guest-conversations-preview-container">
											<div className="guest-conversations-conversation-preview">
												{generateBody(conversationPreview.messageBody) ? '"' + generateBody(conversationPreview.messageBody) + '"' : 'No messages in history'}
											</div>
											</div>
											<div className= "guest-conversations-timestamp-container">
											<div className="guest-conversations-conversation-timestamp">
												{ conversationPreview?.timestamp !== "n/a" ? formatTimestamp(conversationPreview.timestamp) : "" }
											</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						{conversationPreviews ?
							<PaginationFooter
								currentPage={currentPage}
								setCurrentPage={setNewCurrentPage}
								numberOfTotalPreviews={(conversationPreviews).length}
								numberOfPreviews={currentPage}
								pageSize={listSize}
							/>
							: null
						}
					</div>
					{isSideChatOpen && (
						<SideChat
							data={sideChatData}
							arrivalData={arrivalData}
							hotelId={hotelId}
							conversation={(conversations.find(convo => convo.id === currentGuestPhoneNumber)).conversation}
							guestPhoneNumber={currentGuestPhoneNumber}
							constructor={"conversations"}
							isOpen={isSideChatOpen}
							toggleSideChat={closeSideChat}
							addToManualConversations={addToManualConversations}
							removeFromManualConversations={removeFromManualConversations}
							toggleManualSwitch={toggleManualSwitch}
							isInManualMode={isInManualMode}
							toggleBlockOverlay={toggleBlockOverlay}
							handleBlockUnblockClick={handleBlockUnblockClick}
							showBlockOverlay={showBlockOverlay}
							onBackButtonClicked={onBackButtonClicked}
							isBlocked={isBlocked}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default InboxPanel;
