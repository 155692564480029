import React from 'react';
import moment from 'moment';
import './style.scss';
import axios from "axios";
import toast from 'react-hot-toast';

class BlastMessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      allInHouse: false,
      messageContent: '',
      arrivalDateRangeInputType: 'text',
      allInHouseInputType: 'text',
      isSendButtonDisabled: true,
      isArrivalDateRangeDisabled: false,
      isAllInHouseDisabled: false,
      isMessageTextDisabled: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleAllInHouse = this.toggleAllInHouse.bind(this);
  }

    toggleAllInHouse() {
        this.setState(prevState => ({
            allInHouse: !prevState.allInHouse,
            isArrivalDateRangeDisabled: !prevState.allInHouse,
            startDate: !prevState.allInHouse ? '' : prevState.startDate,
            endDate: !prevState.allInHouse ? '' : prevState.endDate,
            isMessageTextDisabled: !prevState.isMessageTextDisabled
        }));
    }
    

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    
        if (name === 'startDate' || name === 'endDate') {
            this.setState({
                allInHouse: false,
                isMessageTextDisabled: false
            });
        }
    }
    sanitizePhoneNumber(input) {
        const sanitizedValue = input.replace(/[^+\d]/g, '');
        const formattedValue = sanitizedValue.startsWith('+') ? sanitizedValue : `+1${sanitizedValue}`;
        return formattedValue;
    }

    convertDate(dateString) {
        if (dateString) {
            const formattedDate = moment(dateString).format("MM/DD/YYYY");
            return formattedDate
        } else {
            return '';
        }
    }

    alertToast = (message, color, icon, duration = 3000) => {
        const id = toast((t) => (
        <div>
            <span onClick={() => toast.dismiss(t.id)}>
            {message}
            </span>
        </div>
        ), {
        position: 'top-right',
        icon: icon,
        duration: duration,
        style: {
            background: color,
            color: '#ffffff',
        }
        });
        return id;
    }

    getDatesBetween(startDate, endDate) {
        const dateArray = [];
        let currentDate = new Date(startDate);
        
        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray.map(date => date.toISOString().split('T')[0]);
    }
    

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({isSendButtonDisabled: true})
        try {
            const hotelId = localStorage.getItem('marie_hotel_id')
            const allGuestsToMessage = await axios.get(`/api/guests/${hotelId}/all`);
            const hotelPhoneNumber = localStorage.getItem('marie_hotel_phone_number');
            if((this.state.startDate !== '' || this.state.endDate !== '') && !this.state.allInHouse){
                const toastId = this.alertToast('Sending messages...', '#01a0c6', '⏳', Infinity)
                const startDate = this.state.startDate || this.state.endDate;
                const endDate = this.state.endDate || this.state.startDate;
                let dates = [startDate, endDate];
                dates.sort();
                const allDates = this.getDatesBetween(dates[0], dates[1]);
                const phoneNumberList = new Set();
                for (const guest of allGuestsToMessage?.data){
                    const initConversationBody = {
                        hotelId: hotelId,
                        guestPhoneNumber: guest?.PhoneNumber,
                        hotelPhoneNumber: hotelPhoneNumber,
                        messageContent: this.state.messageContent,
                    }
                    for (const date of allDates) {
                        if(date >= guest?.checkinDate && date < guest?.checkoutDate && !phoneNumberList.has(guest?.PhoneNumber)){
                            await axios.post("/api/chat/send-message", initConversationBody);
                            phoneNumberList.add(guest?.PhoneNumber);
                        }
                    }
                }
                toast.dismiss(toastId);
            }
            else if(this.state.allInHouse === true){
                const toastId = this.alertToast('Sending messages...', '#01a0c6', '⏳');
                const date = new Date(Date.now());
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;            
                allGuestsToMessage?.data.map(async guest => {
                    const initConversationBody = {
                        hotelId: hotelId,
                        guestPhoneNumber: guest?.PhoneNumber, 
                        hotelPhoneNumber: hotelPhoneNumber,
                        messageContent: this.state.messageContent,
                    }
                    if(formattedDate >= guest?.checkinDate && formattedDate < guest?.checkoutDate){
                        await axios.post("/api/chat/send-message", initConversationBody);
                    }
                });
                toast.dismiss(toastId);
            }
            this.props.onFormSent()
        } catch (error) {
            console.error('there was an error sending the message', error)
            this.alertToast('Message Failed To send', '#CF4343', '⚠️')
        }
        this.setState({isSendButtonDisabled: false})
    }

  render() {
    return (
        <form className='blast-messaging-form' onSubmit={this.handleSubmit}>
            <div className="blast-input-wrapper">
                <input 
                    type="date"
                    name="startDate"
                    value={this.state.startDate}
                    onChange={this.handleChange}
                    disabled={this.state.isDateRangeDisabled}
                    className={this.state.isDateRangeDisabled ? "entry-disabled" : ""}
                />
                <span> to </span>
                <input 
                    type="date"
                    name="endDate"
                    value={this.state.endDate}
                    onChange={this.handleChange}
                    disabled={this.state.isDateRangeDisabled}
                    className={this.state.isDateRangeDisabled ? "entry-disabled" : ""}
                />
                <div className='or-label'>
                    -OR-
                </div>
                <input
                    className={(this.state.allInHouse) ? "all-in-house-button-on" : "all-in-house-button-off"}
                    name="allInHouse"
                    type={'button'}
                    value="All In House"
                    onClick={this.toggleAllInHouse}
                ></input>
            </div>
            <div className="input-wrapper textarea-wrapper">
                <textarea
                    name="messageContent" 
                    id="message-text"
                    value={this.state.messageContent} 
                    onChange={this.handleChange} 
                    placeholder="Write message here..."
                    disabled={(this.state.startDate !== '' || this.state.endDate !== '' || this.state.allInHouse !== false) ? "" : "disabled"}
                    className={(this.state.startDate !== '' || this.state.endDate !== '' || this.state.allInHouse !== false) ? "": "entry-disabled"}
                >
                </textarea>
            </div>
            <div className="blast-submit-wrapper">
                <div className="spacer"></div>
                <input type="submit" value="Send" id='blast-send-button' disabled={((this.state.allInHouse === '' && this.state.startDate === '') || this.state.messageContent === '') ? "disabled" : ""} className={`blast-send-button ${(this.state.allInHouse === '' && this.state.startDate === '') || this.state.messageContent === '' ? "send-button-disabled" : ""}`}/>
            </div>
        </form>
    );
  }
}

export default BlastMessageForm