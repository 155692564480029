import React, { useRef, useState } from 'react';
import './style.scss';
import axios from 'axios';
import { useEffect } from 'react';
import { error, success } from "../../components/Notification";
import toast, {Toaster} from 'react-hot-toast';
import loadingSpinner from '../../assets/eclipse-1s-200px.gif'
const AddAdminModal = ({show, onClose, onChangeAdminName, onChangeAdminPhoneNumber, onChangeAdminEmail, onAddAdmin}) => {
  if (!show) {
    return null;
  }else{
    return (
      <div className="modal" id="addAdminModal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>New Property Admin</h2>
            <span className="close" onClick={onClose}>&times;</span>
          </div>
          <input className="settings-input" placeholder="Name" onChange={(e) => onChangeAdminName(e.target.value)}></input>
          <input className="settings-input" placeholder="Phone Number" onChange={(e) => onChangeAdminPhoneNumber(e.target.value)}></input>
          <input className='settings-input' placeholder='Email' onChange={(e) => onChangeAdminEmail(e.target.value)}></input>
          <div className="modal-footer">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button className="submit-button" onClick={onAddAdmin}>Add Admin</button>
          </div>
        </div>
      </div>
    ) 
  }
}

const SettingsPanel = ({chatToken, hotelId}) => {
  const [selectedAdminNumber, setSelectedAdminNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('admins');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [adminName, setAdminName] =  useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [admins, setAdmins] = useState([]);
  const [emailNotificationTime, setEmailNotificationTime] = useState('n/a');
  const [phoneNotificationTime, setPhoneNotificationTime] = useState('n/a');
  const [overlayVisible, setOverlayVisible] = useState({});

  const buttonRefs = useRef([]);
  const popperRefs = useRef([]);
  const popperInstances = useRef([]);

  const successToastId = 'success-toast';
  const errorToastId = "error-toast";

  useEffect(() => {
    getAdminNotificationTimes();
  },[]);

  const getAdminNotificationTimes = async (phoneNumber) => {
    let admin = admins?.filter(function(admin){
      return admin.Mobile === phoneNumber;
    })
    return admin[0];
  }

  const handleAdminChange = async (e) => {
    const selectedNumber = e.target.value;
    setSelectedAdminNumber(selectedNumber);

    if (selectedNumber) {
      const selectedAdmin = await getAdminNotificationTimes(selectedNumber);
      if (selectedAdmin) {
        setPhoneNotificationTime(selectedAdmin.phoneNotificationTime || 'n/a');
        setEmailNotificationTime(selectedAdmin.emailNotificationTime || 'n/a');
      } else {
        setPhoneNotificationTime('n/a');
        setEmailNotificationTime('n/a');
      }
    }
  };

  const toggleOverlay = (index) => {
    setOverlayVisible(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };
  
  const handleNotificationsSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const formProps = Object.fromEntries(formData);
    const result = await axios.post(`/api/settings/adminEmailNotifications/${hotelId}`, formProps);
    setAdmins(result.data)    
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'admins':
        return (
          <>
            <div className='admin-body'>
              {!admins ? (
                <>
                  <img src={loadingSpinner}></img>
                </>
              ) : 
                <>
                  <div className="admin-container">
                    {Object.values(admins).map((admin, index) => (
                      <div className="settings-admin-row" key={index}>
                        <div className="just-switch-container">
                          <span className="settings-admin-name">{admin.Name}</span>
                        </div>
                        <div
                          className="settings-delete-button"
                          ref={element => { buttonRefs.current[index] = element; }}
                          onClick={() => toggleOverlay(index)}
                        >
                          <span className='dots' >&#8942;</span>
                        </div>
                        {overlayVisible[index] && (
                          <div className='delete-overlay-container'>
                            <div
                              className={`delete-overlay`}
                              ref={element => { popperRefs.current[index] = element; }}
                            >
                              <p onClick={() => deleteAdmin(index, admin.Mobile)}>Delete Admin</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="settings-buttons-row">
                    <button className="send-button" onClick={openModal}>Add Admin</button>
                    {isAllowedToDeleteOldConversations(hotelId) ? (
                      <button className='send-button' id='delete-conversations-button' onClick={deleteOldConversations}>Delete old conversations</button>
                    ) : null}
                  </div>

                </>
              }
            </div>
          </>
        )
      case 'notifications':
        const data = admins;
        return (
          <>
            <form className='escalations-dashboard' onSubmit={handleNotificationsSubmit}>
              <div className='notifications-body'>
                <div className='notifications-grid'>
                  <div className='left-panel-content'>
                    <div className='gm-escalation-body'>
                      <div className='gm-email-ctn'>
                        <div className='gm-email-content'>
                          <div>
                            Admin
                          </div>
                          <select 
                            name="adminNumber" 
                            className='admin-option' 
                            value={selectedAdminNumber}
                            onChange={handleAdminChange}
                          >
                            <option value={null}>Select an Admin</option>
                          {Object.values(admins).map(admin => {
                            return <option key={admin.Mobile} value={admin.Mobile}>{admin.Name}</option>;
                          })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='gm-escalation-time-ctn'>
                      <div className='gm-escalation-label'>
                        Text Notification Time
                      </div>
                      <div>
                        <select 
                          id='escalation-times' 
                          name='phoneNotificationTime'
                          onChange={(e) => setPhoneNotificationTime(e.target.value)}
                          value={phoneNotificationTime}
                        >
                          <option value='n/a'>OFF</option>
                          <option value='1'>Immediate</option>
                          <option value='10'>10 Minutes</option>
                          <option value='15'>15 Minutes</option>
                          <option value='20'>20 Minutes</option>
                          <option value='30'>30 Minutes</option>
                        </select>
                      </div>
                    </div>
                    <div className='gm-escalation-time-ctn'>
                      <div className='gm-escalation-label'>
                        Email Notification Time
                      </div>
                      <div>
                        <select 
                          id='escalation-times' 
                          name='emailNotificationTime'
                          onChange={(e) => setEmailNotificationTime(e.target.value)}
                          value={emailNotificationTime}
                        >
                          <option value='n/a'>OFF</option>
                          <option value='1'>Immediate</option>
                          <option value='10'>10 Minutes</option>
                          <option value='15'>15 Minutes</option>
                          <option value='20'>20 Minutes</option>
                          <option value='30'>30 Minutes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {data && Object.keys(data).length > 0 && (
                    <>
                      <div className='admin-info-ctn'>
                        <div className='admin-info-content'>
                          <div className='admin-contact-info-label'>Admin Alert Information</div>
                          <div className='admin-grid'>
                            {Object.values(data).map((settings, index) => (
                              <React.Fragment key={index}>
                                <div className='admin-notification-table'>
                                  <div className='gm-info-key'>Admin:</div>
                                  {settings.Name ? (
                                    <div className='gm-info-value'>{settings.Name}</div>
                                  ) : (
                                    <div className='gm-info-value'>n/a</div>
                                  )}
                                  <div className='gm-info-key'>Phone Number:</div>
                                  {settings.Mobile ? (
                                    <div className='gm-info-value'>{settings.Mobile}</div>
                                  ) : (
                                    <div className='gm-info-value'>n/a</div>
                                  )}
                                  <div className='gm-info-key'>Text Notification Time:</div>
                                  {!settings.phoneNotificationTime || settings.phoneNotificationTime === 'n/a' ? (
                                    <div className='gm-info-value'>OFF</div>
                                  ) : settings.phoneNotificationTime === '1' ? (                                    
                                    <div className='gm-info-value'>Immediate</div>
                                  ) : (
                                    <div className='gm-info-value'>{settings.phoneNotificationTime} Minutes</div>
                                  )}
                                  <div className='gm-info-key'>Email:</div>
                                  {settings.Email ? (
                                    <div className='gm-info-value'>{settings.Email}</div>
                                  ) : (
                                    <div className='gm-info-value'>n/a</div>
                                  )}
                                  <div className='gm-info-key'>Email Notification Time:</div>
                                  {!settings.emailNotificationTime || settings.emailNotificationTime === 'n/a' ? (
                                    <div className='gm-info-value'>OFF</div>
                                  ) : settings.emailNotificationTime === '1' ? (
                                    <>
                                      <div className='gm-info-value'>Immediate</div>
                                    </>
                                  ) : (
                                    <div className='gm-info-value'>{settings.emailNotificationTime} Minutes</div>
                                  )}
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                    )}
                </div>
              </div>
              <div className='escalations-save-button-ctn'>
                <input type='submit' value='Save Changes' className='escalations-save-button' />
              </div>
            </form>
          </>
        )
      case 'logs':
        return (
          <></>
        )
      default:
        return null;
    }
  }

  const logOut = async () => {
    axios.post('/api/auth/signout', {
      access_token: localStorage.getItem('marie_access_token')
    })
      .then(response => {
        localStorage.removeItem('marie_access_token')
        localStorage.removeItem('marie_hotel_id')
        window.location.href = response.data.redirectUrl
      })
      .catch(error => {
        console.error('unable to sign out', error)
      })
  }

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false);
  }

  const fetchAdmins = async () => {
    const response = await axios.get(`/api/settings/${hotelId}`)
      const admins = response.data?.map(admin => {
        return {
          Name: admin?.Name,
          Mobile: admin?.Mobile,
          Email: admin?.Email,
          notifications: admin?.Notifications,
          phoneNotificationTime: admin?.phoneNotificationTime,
          emailNotificationTime: admin?.emailNotificationTime
        };
      }) || [];
      setAdmins(admins);
  }

  const addAdmin = async () => {
    if(!adminName || !adminPhoneNumber || !adminEmail){
      alertToast("Please fill in all required fields", '#CF4343', '⚠️')
      return;
    }
    const newAdmin = {
      Name: adminName,
      Mobile: adminPhoneNumber,
      Email: adminEmail,
      notifications: {
        notifications: false,
        email: false,
        phone: false,
      }
    }
    setAdmins(prevAdmins => {
      const updatedAdmins = {...prevAdmins, [newAdmin.Mobile]: newAdmin};
      updateAdmins(updatedAdmins, newAdmin.Mobile);
      return updatedAdmins;
    });
  }

  const deleteAdmin = async(index, adminNumber) => {
    if (popperInstances[index]) {
      popperInstances[index].destroy();
      popperInstances[index] = null;
    }
    const body = {admin: adminNumber};
    try{
      const deleteAdmin = await axios.put(`/api/settings/deleteAdmin/${hotelId}`, body);
      alertToast("Successfully Deleted Admin", '#01a0c6', '✓');
    }catch(error){
      alertToast("Failed to Delete Admin", "#cf4343", "⚠️");
    }
    const updatedAdmins = Object.values(admins).filter(admin => admin?.Mobile !== adminNumber);
    setAdmins(updatedAdmins);
    setOverlayVisible(prev => ({ ...prev, [index]: false}))
  }

  const alertToast = (message, color, icon, id) => {
    toast.dismiss(id);
    toast((t) => (
      <div>
        <span onClick={() => toast.dismiss(t.id)}>
          {message}
        </span>
      </div>
    ), {
      position: 'top-right',
      icon: icon,
      duration: 3000,
      style: {
        background: color,
        color: '#ffffff',
      }
    });
  }
  const updateAdmins = async (admins, adminNumber) => {
    const adminForBackend = Object.values(admins).map(admin => ({
      Name: admin.Name,
      Mobile: admin.Mobile,
      Email: admin.Email,
      Notifications: admin.notifications,
    }));
    try {
      const response = await axios.put(`/api/settings/${hotelId}`, { propertyAdmin: adminForBackend, number: adminNumber });
      setAdmins(admins);
      closeModal();
      alertToast("New Admin Added Successfully", "#01a0c6", "✓", successToastId);
      return response;
    } 
    catch (error) {
      console.error("Failed to update admins:", error);
      alertToast("Failed to Add New Admin ", "#cf4343", "⚠️", errorToastId)
      throw error;
    }
  };

  const isAllowedToDeleteOldConversations = (hotelId) => {
    const allowedHotelIds = [
      '5d777dee-2fb0-4dfc-aa17-eaa9a4377f5d',
      '36f9bfce-01dc-47e4-b6c4-d1a057707c40',
      '228d1b3f-dc0e-46f7-b0fb-22a73018fb5a'
    ]
    return allowedHotelIds.includes(hotelId)
  }

  const deleteOldConversations = async () => {
    const autoClose = true

    if (!isAllowedToDeleteOldConversations(hotelId)) {
      return error('You are not allowed to delete old conversations');
    }

    try {
      console.log("Deleting old conversations...")
      await axios.delete('/api/settings/conversations/');
      success('Old conversations deleted', autoClose);
    } catch (err) {
      error('Unable to delete old conversations', autoClose);
    }
  }

  useEffect(() => {
    fetchAdmins();
  }, [])

    return (
      <>
        <div className="settings-panel-body">
          <AddAdminModal
            show={showModal}
            onClose={closeModal}
            onChangeAdminName={(value) => setAdminName( value )}
            onChangeAdminPhoneNumber={(value) => setAdminPhoneNumber(value)}
            onChangeAdminEmail={(value) => setAdminEmail(value)}
            onAddAdmin={addAdmin}
          />
          <div className="settings-panel-content">
            <div className="settings-panel-header">
              <div className="settings-panel-title">Settings</div>
              <button className="sign-out-button" onClick={logOut}>Sign Out</button>
            </div>
            <div className="settings-conversation-container">
              <div className='settings-tab-nav'>
                <div className={`settings-tab-button ${activeTab === 'admins' ? 'active' : ''}`} onClick={() => setActiveTab('admins')}>
                  Admins
                </div>
                <div className={`settings-tab-button ${activeTab === 'notifications' ? 'active' : ''}`}
                onClick={() => setActiveTab('notifications')}>
                  Notifications
                </div>
                {/* <div className={`settings-tab-button ${activeTab === 'escalations' ? 'active' : ''}`}
                onClick={() => setActiveTab('escalations')}>
                  Email
                </div> */}
                {/* <div className={`settings-tab-button ${activeTab === 'logs' ? 'active' : ''}`}
                onClick={() => setActiveTab('logs')}>
                  Actions Log
                </div> */}
              </div>
              {renderTabContent()}
            </div>
          </div>
        </div>
      </>
    )
}

export default SettingsPanel;
