import React, { useState } from 'react';
import moment from 'moment';
import './style.scss';
import axios from "axios";
import toast, {Toaster} from 'react-hot-toast';
const GuestUploadForm = ({onGuestAdd}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [arrivalInputType, setArrivalInputType] = useState("text");
  const [departureInputType, setDepartureInputType] = useState("text");

  const adjustDateByDays = (baseDate, days) => {
    let resultDate = moment(baseDate, "YYYY-MM-DDTHH:mm:ss.SSS");
    resultDate = resultDate.add(parseInt(days, 10), 'days');
    return resultDate.format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // If the field is 'phoneNumber', sanitize the input
    if (name === 'phoneNumber') {
      // Use a regular expression to keep only digits and certain characters
      const sanitizedValue = value.replace(/[^\d+() \-]/g, '');
      
      // Update the state with the sanitized and formatted value
      setPhoneNumber(sanitizedValue);
    } else {
      switch (name) {
        case 'firstName':
          setFirstName(value);
          break;
        case 'lastName':
          setLastName(value);
          break;
        case 'roomNumber':
          setRoomNumber(value);
          break;
        case 'arrivalDate':
          setArrivalDate(value);
          break;
        case 'departureDate':
          setDepartureDate(value);
          break;
        default:
          break;
      }
    }
  }
  
  const sanitizePhoneNumber = (input) => {
    // Remove any non-digit, non-plus characters
    const sanitizedValue = input.replace(/[^+\d]/g, '');
    
    // Ensure +1 at the beginning if not already there
    const formattedValue = sanitizedValue.startsWith('+') ? sanitizedValue : `+1${sanitizedValue}`;
    
    return formattedValue;
  }
  
  const convertDate = (dateString) => {
    if (dateString) {
      const formattedDate = moment(dateString).format("MM/DD/YYYY");
      return formattedDate
    } else {
      return '';
    }
  }
  
  const alertToast = (message, color, icon) => {
    toast((t) => (
      <div>
        <span onClick={() => toast.dismiss(t.id)}>
          {message}
        </span>
      </div>
    ), {
      position: 'top-right',
      icon: icon,
      duration: 3000,
      style: {
        background: color,
        color: '#ffffff',
      }
    });
  }
  const dateValidate = (date) => {
    return !isNaN(new Date(date));
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const hotelId = localStorage.getItem('marie_hotel_id');
      const sanitizedPhoneNumber = sanitizePhoneNumber(phoneNumber);
      const hotelData = (await axios.get(`/api/hotel/${hotelId}/hotel-data`)).data.hotelData;
      const scheduledActions = hotelData.ScheduledActions;
      const addGuestBody = {
        firstName,
        lastName,
        phoneNumber: sanitizedPhoneNumber,
        checkinDate: arrivalDate,
        checkoutDate: departureDate,
        roomNumber
      }
      Object.keys(scheduledActions).forEach(actionKey => {
        const action = scheduledActions[actionKey];
        const time_delta = action.time_delta;
        const time_hours = action.time;
        let baseDate, adjustedDate;
        if(action.base_date == 'arrival'){
          baseDate = `${arrivalDate}T${time_hours}:00.00`;
          adjustedDate = adjustDateByDays(baseDate, time_delta);
          addGuestBody[actionKey] = adjustedDate;  
        }else if(action.base_date == 'departure'){
          baseDate = `${departureDate}T${time_hours}:00.00`;
          adjustedDate = adjustDateByDays(baseDate, time_delta);
          addGuestBody[actionKey] = adjustedDate;
        }
      });
      
      if(addGuestBody.phoneNumber == '+1'){
        alertToast("Please fill in a Phone Number", '#CF4343', '⚠️');
        return;
      }
      if(!dateValidate(addGuestBody.checkinDate)){
        alertToast("Please fill in the Arrival Date", '#CF4343', '⚠️');
        return;
      }
      if(!dateValidate(addGuestBody.checkoutDate)){
        alertToast("Please fill in the Departure Date", '#CF4343', '⚠️');
        return;
      }
      const addGuestResponse = await axios.post(`/api/guests/${hotelId}/add-single-guest`, addGuestBody);
      if(addGuestBody.firstName === ''){
        alertToast("Guest added.", '#01a0c6', '✓');
      }else{
        alertToast(addGuestBody.firstName + " added.", '#01a0c6', '✓');
      }
      onGuestAdd(addGuestBody);
    } catch (error) {
      console.error('there was an error adding the guest', error)
      alertToast("Unable to add guest.", '#cc0000', '⚠️');
    }
  }

  const handleArrivalFocus = () => setArrivalInputType('date');
  const handleArrivalBlur = () => setArrivalInputType('text');
  const handleDepartureFocus = () => setDepartureInputType('date');
  const handleDepartureBlur = () => setDepartureInputType('text');

  return (
    <form onSubmit={handleSubmit}>
      <div className='guest-upload-body' id='form-content'>
        <div className="input-wrapper">
          <input 
            type="text"
            name="firstName" 
            value={firstName} 
            onChange={handleChange} 
            placeholder="Guest First Name" />
          <input 
            type="text" 
            name="lastName"
            value={lastName} 
            onChange={handleChange} 
            placeholder="Guest Last Name" />
            
        </div>
        <div className="input-wrapper">
          <input 
            type="tel" 
            value={phoneNumber}
            name="phoneNumber"
            onChange={handleChange} 
            placeholder="Guest Phone Number" />
          <input 
              type="text" 
              value={roomNumber}
              name="roomNumber"
              onChange={handleChange} 
              placeholder="Guest Room Number" />
        </div>
        <div className="input-wrapper">
          <input 
            placeholder="Arrival Date"
            type={arrivalInputType}
            onFocus={handleArrivalFocus}
            onBlur={handleArrivalBlur}
            name="arrivalDate"
            value={arrivalInputType == 'date' ? arrivalDate : convertDate(arrivalDate)}
            onChange={handleChange} />
          <input 
            placeholder="Departure Date"
            type={departureInputType}
            onFocus={handleDepartureFocus}
            onBlur={handleDepartureBlur}
            name="departureDate"
            value={departureInputType == 'date' ? departureDate : convertDate(departureDate)} 
            onChange={handleChange} />
        </div>
        <div className='submit-wrapper'>
          <div className="add-guest-wrapper">
            {/* <div className="spacer"></div> */}
            <input type="submit" value="Add Guest"/>
          </div>
        </div>
      </div>
    </form>
  );
}
export default GuestUploadForm;
