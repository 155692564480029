import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import chevronDown from '../../../assets/chevron-down.svg';
import cross from '../../../assets/cross.png';
import axios from 'axios';

function RequestsFilter({ onChange, displayConversation }) {
  const [activeFilters, setActiveFilters] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const hotelId = localStorage.getItem('marie_hotel_id');
  const [filterOptions, setFilterOptions] = useState({'INCOMPLETE': {name: "Open" }, 'COMPLETE': {name: "Closed" }});

  const handleFilterChange = (filter) => {
    let updatedFilters;
    if (activeFilters.includes(filter)) {
      updatedFilters = activeFilters.filter(f => f !== filter);
    } else {
      updatedFilters = [...activeFilters, filter];
    }
    if (filter === "Open") {
      updatedFilters = [...updatedFilters.filter(f => f !== "Closed"), "INCOMPLETE"];
    } else if (filter === "Closed") {
      updatedFilters = [...updatedFilters.filter(f => f !== "Open"), "COMPLETE"];
    }
    setActiveFilters(updatedFilters);
    onChange(updatedFilters);
  };
  const getFilters = async () => {
    try {
      const response = await axios.get(`/api/requests/request-filters/${hotelId}`);
      const fetchedOptions = response.data;
      const updatedOptions = { ...filterOptions, ...fetchedOptions };
      setFilterOptions(updatedOptions);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  const clearAllFilters = () => {
    setActiveFilters([]);
    onChange([]);
  };

  useEffect(() => {
    getFilters();
  }, [])

  return (
    <>
      <div className="filters-container">
        <div className='filters-ctn'>
          <div className="filter-selector" onClick={() => setShowDropdown(!showDropdown)}>
            <div id='filter-label'>
              Filters
            </div>
            <div id='arrow-label'><img src={chevronDown}></img></div>
          </div>
          {showDropdown && (
            <ul className="filter-dropdown-menu" ref={dropdownRef}>
              <div className='cancel-filter'><img className='cancel-filter-image' src={cross} onClick={() => setShowDropdown(false)}></img></div>
              {Object.entries(filterOptions).map(([key, val], index) => (
                <li key={index} className='filter-item'>
                  <label className='filter-item-label'>
                    <input
                      className='filter-checkbox'
                      type="checkbox"
                      checked={activeFilters.includes(key) || (key.name === "Open" && activeFilters.includes("INCOMPLETE")) || (key.name === "Closed" && activeFilters.includes("COMPLETE"))}
                      onChange={() => handleFilterChange(key)}
                    />
                    {val.name}
                  </label>
                </li>
              ))}
            </ul>
          )}
          <div className="selected-filters">
            {activeFilters.map((key, index) => (
              <>
                <div key={key} className="selected-filter-ctn">
                  <div className='selected-filter-cell'>
                    <div className='selected-filter-label'>{key}</div>
                    <div className='selected-filter'>
                      <img className='cancel-filter-image' src={cross} alt="Remove Filter" onClick={() => handleFilterChange(key)}/>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {activeFilters.length > 0 && (
              <div className='clear-filters-btn-ctn'>
                <div className="clear-filters-btn" onClick={clearAllFilters}>
                  Clear Filters
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestsFilter;