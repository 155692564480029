import './style.scss'

const Snapshot = ({title, content}) => {
    return(
        <>
            <div className="snapshot-container score-container">
                <div className="snapshot-header">
                    <div>{title}</div>
                </div>
                <div className="snapshot-body">
                    <div className="snapshot-text">{content}</div>
                </div>
            </div>        
        </>
    )
}

export default Snapshot;