import React, { useEffect, useState } from 'react';
import checkmarkIcon from '../../../assets/checkmark-icon.png';
import exclamationPointIcon from '../../../assets/exclamation-point-icon.png';
import gearIcon from '../../../assets/gear-icon.png';
import notificationSound from '../../../assets/notification-sound.mp3'
import toast from 'react-hot-toast';
import moment from 'moment-timezone';
import './style.scss';
import axios from 'axios';
import bell from '../../../assets/bell.png';

const GuestRequest = ({request, hotelId, updateRequestStatus, displayConversation, filters}) => {
  const [menuHidden, setMenuHidden] = useState(true);
  const [checked, setChecked] = useState(request.requestStatus === "COMPLETE");
  const [requestStyles, setRequestStyles] = useState(filters);
  const [requestCategory, setRequestCategory] = useState(request.requestCategory);

  useEffect(() => {
    const alertTimestamp = request.creationTimestamp;
    const difference = (15000 + alertTimestamp) - Date.now();
    if (difference > 0 && request.requestStatus === 'INCOMPLETE') {
      setTimeout(alertToast(`${request.guestName}'s request needs your attention!`, '#cf4343', bell), difference);
    }
  }, []);

  const determineStatusImage = () => {
    if (request.requestStatus === 'COMPLETE') {
      return checkmarkIcon
    } else if (request.requestStatus === 'INCOMPLETE') {
      return exclamationPointIcon
    } else {
      return gearIcon
    }
  }

  const determineStatusImageCssClass = () => {
    if (checked === true) {
      return 'complete'
    } else {
      return 'incomplete'
    } 
  }
  
  const onIconClick = () => {
    setMenuHidden(!menuHidden);
  }
  
  const getRequestText = () => {
    if (request.text.length > 140) {
      return request.text.substring(0,140) + "..."
    } else {
      return request.text
    }
  }

  const getReadableTime = () => {
    const creationMoment = moment.unix(request.creationTimestamp/1000).utc().tz('America/Denver')
    const formattedTime = creationMoment.format('h:mm A')
    return formattedTime
  }
  
  const messageGuest = () => {
    window.location = `/inbox?guestPhoneNumber=${request.guestPhoneNumber}`
  }
  const alertToast = (message, color, icon) => {
    toast((t) => (
      <>
        <div>
          <span onClick={() => toast.dismiss(t.id)}>
            {message}
          </span>
        </div>
        <audio src={notificationSound} id='notification-sound'></audio>
      </>
    ), {
      position: 'top-right',
      icon: <img src={icon} style={{width: 20, height: 20}} alt="alert icon" />,
      duration: 3000,
      style: {
        background: color,
        color: '#ffffff',
      }
    });
    playAudio();
  }
  const playAudio = () => {
    const audio = new Audio(notificationSound)
    audio.play();
  }
  const newUpdateRequestStatus = (status) => {
    updateRequestStatus(request, status);
    setChecked(status === "COMPLETE");
  }
    
    return (
      <>
        <div className="guest-request-centering-container">
          <div className="guest-request-container">
            <div className='requests-checkbox-ctn'>
              <div className="check-box-container" popuptext={`Mark as ${(checked !== true) ? 'Closed' : 'Open' }`}>
                <input type="checkbox" checked={checked} onChange={(e) => {e.stopPropagation && (checked === true) ? newUpdateRequestStatus('INCOMPLETE') : newUpdateRequestStatus('COMPLETE')}}/>
              </div>
            </div>
            <div className="guest-request-container" onClick={(e) => {
              e.stopPropagation &&
              displayConversation(request.id, request.guestName, request.guestPhoneNumber, request.creationTimestamp);
            }}>
              <div className="guest-request-name-container">
                <div className="guest-request-name">{request?.guestName?.replace('null','')}</div>
              </div>
              {requestStyles && (
                <>
                  <div className='guest-request-category-container' style={{backgroundColor: requestStyles[requestCategory]?.color}}>
                    <div className='guest-request-category'>{requestStyles[requestCategory]?.acronym}</div>
                  </div>
                </>
              )}
              <div className="guest-request-text-container">
                <div>{getRequestText() ? '"' + getRequestText() +'"' : "Media Attachment"}</div>
              </div>
              <div className="request-time-container">
                <div className="request-time">{getReadableTime()}</div>
              </div>
              <div className="guest-request-message-guest-button-container">
                <div className={`status-icon-container ${determineStatusImageCssClass()}`} onClick={onIconClick}>
                  <div className="status-icon-text">{(checked) ? 'Closed' : 'Open'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

export default GuestRequest;